import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import './Login.css';
import {auth} from "./firebase";

function Login() {
  
    const history=useHistory();
    const[email, setEmail]=useState('');
    const[password, setPassword]= useState('');
  const signIn=e=>{
      e.preventDefault();//preventing refreshing the page
        auth.signInWithEmailAndPassword(email,password).
        then(auth=>{
            history.push('/')
        })
        .catch(error=> alert(error.message))
  }
  
  
  const register=e=>{
      e.preventDefault();
      auth.
      createUserWithEmailAndPassword(email,password).then((auth)=>{
          
        if(auth){
            history.push('/')
        }

      })
      .catch(error=>alert(error.message))
  }
    return (
  <div className='login'>
            <Link to="/">
            <img className='login__logo'
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a9/Amazon_logo.svg/2560px-Amazon_logo.svg.png"></img>
            </Link>

            <div className='login__container'>
                <h1>Sign-in</h1>
                <form>
                    <h5>E-mail</h5>
                    <input type="text" value={email} onChange={e => setEmail(e.target.value)}></input>
                    <h5>Password</h5>
                    <input type="password" value={password} onChange={e=>setPassword(e.target.value)}></input>

                    <button type='submit' onClick={signIn} className='login__signInButton'>Sign In</button>
                </form>

                <p>
                By Signing-in, you agree to Amazon's Conditions of Use and Privacy Notice.
                </p>
                
                <button  onClick={register} className='login__registerButton'>Create your Amazon account</button>

            </div>

  </div>
    
    );
}

export default Login;
