import React from 'react';
import './Subtotal.css';
import CurrencyFormat from "react-currency-format";
import { useStateValue } from './StateProvider';
import {useHistory} from "react-router-dom";

function Subtotal() {

    const history=useHistory();
    const[{basket},dispatch]=useStateValue();
    let sum=0;
    for(let i=0;i<basket.length;i++)
    {
        sum+=basket[i].price;
    }

    return (
  <div className='subtotal'>
        <CurrencyFormat
            renderText={(value)=>(
                <>
                <p>
                    Subtotal ({basket?.length} items):
                    <strong>{sum}</strong>
                </p>
                <small className='subtotal__gift'>
                    <input type='checkbox'/>This order contains a gift

                </small>
                </>
            )}

            decimalScale={2}
            value={0    }
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
        />
        <button onClick={e=> history.push('/payment')}>Proceed to checkout</button>
  </div>
    
    );
}

export default Subtotal;
