import React from 'react';
import "./Product.css";
import {useStateValue} from "./StateProvider";

function Product({id,title, image, price, rating}) {
 
  const [{basket}, dispatch]= useStateValue();
 
  
  const addToBasket = ()=>{

    dispatch({

       type:'ADD_TO_BASKET',
       
       item:{
         id:id,
         title:title,
         image:image,
         price:price,
         rating:rating,
       }
     
       
    })
  }
 
 
  return (
  <div className='product'>
    <div className='product__info' id={id}>
        <p>{title}</p>
        <p className='product_price'>
          <small>$</small>
          <strong>{price}</strong>
        </p>
        <div className='product__rating'>
          {Array(rating).fill().map((_,i)=>(
            <p>⭐</p>
          ))}
        
        </div>
    </div>

    <img src={image}></img>

    <button onClick={addToBasket}>Add to Cart</button>
  </div>
    );
}

export default Product;
