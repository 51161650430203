import React from 'react';
import './Header.css'
import { Link } from "react-router-dom";
import { useStateValue } from './StateProvider';
import { auth } from './firebase';

function Header() {

  const [{basket,user},dispatch]=useStateValue();
  const handleAuthentication=()=>{

    if(user)
    {
      auth.signOut();
    }
  
  }
  

  console.log('The user in header is --->',);
  return (<div className='header'>
    
<link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css"/>
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
    
    <Link to="/">
    <img className="header_logo" src="https://pngimg.com/uploads/amazon/amazon_PNG18.png">
    </img>
    </Link>
    
    <div className='header_search'>
      <input className='header_searchInput' type="text"/>
      <i class="fa fa-search" id='search_logo'></i>
    </div>
    <div className='header_nav'>
      <Link to={!user && '/login'}>
        <div onClick={handleAuthentication} className='header_option'>
            <span className='header_optionLineOne'>Hello {user ? user.email:'GUEST'}</span>
            <span className='header_optionLineTwo'>{user ? 'Sign Out': 'Sign In' }</span>
            
        </div>
      </Link>
        <div className='header_option'>
             <span className='header_optionLineOne'>Returns</span>
             <span className='header_optionLineTwo'> and Orders</span>
        </div>
        <div className='header_option'>
            <span className='header_optionLineOne'>Your</span>
            <span className='header_optionLineTwo'>Prime</span>
        </div>
        <Link to="/checkout">
        <div className='header_optionBasket'>
        <i class="fa fa-shopping-cart" id="header_Basket"></i>
        <span className='header_option header_optionLineTwo header_basketCount'>{basket?.length}</span>
        </div>
        </Link>
       
    </div>
    
  </div>);
}

export default Header;
