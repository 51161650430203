import React from 'react';
import './Home.css'
import Product from "./Product";
function Home() {
  return (
    <div className='home'>
        
        <div className='home_container'>
        <img className='home_image' src="https://m.media-amazon.com/images/I/51GfbsdnQvL._SX3000_.jpg">
        </img>

       <div className='home__row'>
         <Product id='100' title='Logitech gaming wireless headphones' price={99.99} image='https://m.media-amazon.com/images/I/81XKunza3xL._AC_UY218_.jpg'
          rating={3}/>

         <Product id='101' title='Amazon Alexa'price={49.99} image='https://m.media-amazon.com/images/I/61EXU8BuGZL._AC_SY200_.jpg'
         rating={5}/>
      </div>

      <div className='home__row'>
      <Product id='102' title='IFB Microwave Owen' price={99.99} image='https://m.media-amazon.com/images/I/71hqJL+egKL._AC_UY218_.jpg'
      rating={4}/>
      <Product id='103' title='Lumix dlsr' price={399.99} image='https://images-eu.ssl-images-amazon.com/images/G/31/Cameras/MSO/379x304_pc_card_1._SY304_CB643056800_.jpg'
      rating={3}/>
      <Product id='104' title='fitbit pro 2' price={199.99} image='https://m.media-amazon.com/images/I/71ZanygHKsL._AC_SY200_.jpg'
      rating={4}/>
      </div>

      <div className='home__row'>
      <Product id='105' title='Samsung ultra wide gaming monitor' price={499.99} image='https://m.media-amazon.com/images/I/71it2biogSS._AC_UY218_.jpg'
      rating={5}/>
      </div>


        </div>
  </div>
  );
}

export default Home;
