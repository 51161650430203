// For Firebase JS SDK v7.20.0 and later, measurementId is optional
import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyA3fWh6tTx0zOm5GDGlc5yEyCPX31c1UjM",
  authDomain: "fir-661d6.firebaseapp.com",
  projectId: "fir-661d6",
  storageBucket: "fir-661d6.appspot.com",
  messagingSenderId: "899177807728",
  appId: "1:899177807728:web:b60dd66154d3d445b6d9b5",
  measurementId: "G-CP5CVFX5V4"
};

const firebaseApp= firebase.initializeApp(firebaseConfig);
const db=firebaseApp.firestore();
const auth=firebase.auth();
export { db, auth};